import moment from "moment"

export const typeMapper = {
  Date: {
    serialize: (date: moment.Moment) => date.format("YYYY-MM-DD"),
    deserialize: (date: string) => moment(date, "YYYY-MM-DD"),
  },
  Time: {
    // FixMe: Array part is a workaround for https://github.com/helios1138/graphql-typed-client/issues/41
    serialize: (time: moment.Moment | moment.Moment[]) => {
      if (Array.isArray(time)) {
        return time.map(t => t.format("HH:mm:ss"))
      }
      return time.format("HH:mm:ss")
    },
    deserialize: (time: string) => moment(time, "HH:mm:ss"),
  },
}
