import * as mui from '@mui/material'
import {Field, Form, Formik, FormikValues} from 'formik'
import {TextField} from 'formik-mui'
import React from 'react'
import { Navigate } from 'react-router-dom'
import * as Yup from 'yup'

import {Link} from 'components/Link'
import {userContext} from 'context/user'
import {routes} from 'routes'
import {graphqlClient} from 'utils/graphql'

import logoIcon from '../assets/logo.png'


const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required').min(8, '8 characters minimum'),
})

export function Login() {
  const user = React.useContext(userContext)

  const [commonErrors, setCommonErrors] = React.useState<string[]>([])

  const urlParams = new URLSearchParams(window.location.search)
  const registered = urlParams.get('registered')

  function handleSubmit(
    values: FormikValues,
    {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}
  ) {
    setCommonErrors([])
    graphqlClient.chain.mutation
      .login({email: values.email, password: values.password})
      .execute({__scalar: 1})
      .then(() => user.refetch())
      .catch(e => {
        if (e.errors[0].extensions.common_errors) {
          setCommonErrors(e.errors[0].extensions.common_errors)
        } else {
          throw e
        }
      })
      .finally(() => setSubmitting(false))
  }

  if (user.data) return <Navigate to={routes.INDEX} />
  
  return (
    <mui.Container component="main" maxWidth="xs">
      <mui.Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logoIcon} alt="logo" height="100"/>
        <mui.Typography component="h1" variant="h5" mt={4}>
          Sign in
        </mui.Typography>
        <Formik
          initialValues={{email: '', password: ''}}
          onSubmit={handleSubmit}
          sx={{mt: 1}}
          validateOnBlur={true}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({submitForm, isSubmitting, touched, errors, isValid}) => (
            <Form style={{width: '100%'}}>
              <mui.Stack spacing={3} mt={3}>
                {registered && (
                  <mui.Alert severity="success">
                    Your account has been created and is pending approval.<br/>
                    You will receive email notification once your application is approved.<br/>
                  </mui.Alert>
                )}
                {commonErrors.map((error, i) => (
                  <mui.Alert key={i} severity="error">
                    {error}
                  </mui.Alert>
                ))}
                <Field
                  component={TextField}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    autoComplete: 'email',
                    fullWidth: true,
                  }}
                  InputLabelProps={{shrink: true}}
                  label="Email address"
                  name="email"
                />
                <Field
                  component={TextField}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    autoComplete: 'current-password',
                    fullWidth: true,
                    type: 'password',
                  }}
                  InputLabelProps={{shrink: true}}
                  label="Password"
                  name="password"
                />
                <mui.Button
                  disabled={isSubmitting || !isValid}
                  fullWidth
                  onClick={submitForm}
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1.5,
                    backgroundColor: '#A8C02A',
                    '&:hover': {
                      backgroundColor: '#C1CC00',
                    },
                }}
                  variant="contained"
                >
                  Sign In
                </mui.Button>
                <mui.Grid container>
                  {/*<mui.Grid item xs>*/}
                  {/*  <mui.Link href="#" variant="body2">*/}
                  {/*    Forgot password?*/}
                  {/*  </mui.Link>*/}
                  {/*</mui.Grid>*/}
                  <mui.Grid item>
                    <Link to={routes.REGISTER} variant="body2">
                      Don't have an account? Request access
                    </Link>
                  </mui.Grid>
                </mui.Grid>
              </mui.Stack>
            </Form>
          )}
        </Formik>
      </mui.Box>
    </mui.Container>
  )
}