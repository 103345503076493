import {Form, Formik} from 'formik'
import * as React from 'react'
import * as mui from '@mui/material'
import * as yup from 'yup'

import {CenteredSpinner} from 'components/CenteredSpinner'
import {userContext} from 'context/user'
import {
  Calculate,
  CalculationInput,
  MainUserCountryChoices,
  ResultsUnitsAmount,
  ResultsUnitsPeriod,
  Units,
} from 'types/graphql/schema'
import {graphqlClient, useQuery} from 'utils/graphql'
import {convertCurrencyInputs} from 'utils/units'
import {renderValues} from 'utils/values'

import logoAdisseo from './assets/adisseo.png'
import {Main} from './Main'

const herdSumErrMsg = 'Herd structure must sum to 100'
const validationSchema = yup.object().shape({
  units: yup.string().oneOf(Object.values(Units)).required(),
  resultsUnitsAmount: yup.string().oneOf(Object.values(ResultsUnitsAmount)).required(),
  resultsUnitsPeriod: yup.string().oneOf(Object.values(ResultsUnitsPeriod)).required(),
  currencyId: yup.string().required(),
  herdSize: yup.number().min(0).integer().required('Required'),
  aabCents: yup.number().min(0).required('Required'),
  isPlusDmi: yup.boolean().required(),
  isAab: yup.boolean().required(),
  productId: yup.string().required(),
  productPrice: yup.number().min(0).required(),
  regionId: yup.string().required(),

  // Investment section
  investmentHerdStructureFarOff: yup.number().min(0).required(),
  investmentHerdStructureCloseUp: yup.number().min(0).required(),
  investmentHerdStructureFresh: yup.number().min(0).required(),
  investmentHerdStructureEarlyMidLact: yup.number().min(0).required(),
  investmentHerdStructureLateLact: yup.number().min(0).required(),

  investmentProductGramsPerCowDayCloseUp: yup.number().min(0).required(),
  investmentProductGramsPerCowDayFresh: yup.number().min(0).required(),
  investmentProductGramsPerCowDayEarlyMidLact: yup.number().min(0).required(),
  investmentProductGramsPerCowDayLateLact: yup.number().min(0).required(),

  investmentDmiFarOff: yup.number().min(0).required(),
  investmentDmiCloseUp: yup.number().min(0).required(),
  investmentDmiFresh: yup.number().min(0).required(),
  investmentDmiEarlyMidLact: yup.number().min(0).required(),
  investmentDmiLateLact: yup.number().min(0).required(),

  investmentDmCostCurrentFarOff: yup.number().min(0).required(),
  investmentDmCostCurrentCloseUp: yup.number().min(0).required(),
  investmentDmCostCurrentFresh: yup.number().min(0).required(),
  investmentDmCostCurrentEarlyMidLact: yup.number().min(0).required(),
  investmentDmCostCurrentLateLact: yup.number().min(0).required(),

  // Short term returns section
  shortTermReturnsMilkCurrent: yup.number().min(0).required(),
  shortTermReturnsMilkDifference: yup.number().min(0).required(),
  shortTermReturnsMilkFmmoPrice: yup.number().min(0).required(),

  shortTermReturnsFatCurrent: yup.number().min(0).required(),
  shortTermReturnsFatDifference: yup.number().min(0).required(),
  shortTermReturnsFatFmmoPrice: yup.number().min(0).required(),

  shortTermReturnsProteinCurrent: yup.number().min(0).required(),
  shortTermReturnsProteinDifference: yup.number().min(0).required(),
  shortTermReturnsProteinFmmoPrice: yup.number().min(0).required(),

  shortTermReturnsOtherSolidsCurrent: yup.number().min(0).required(),
  shortTermReturnsOtherSolidsDifference: yup.number().min(0).required(),
  shortTermReturnsOtherSolidsFmmoPrice: yup.number().min(0).required(),

  shortTermReturnsPremiumFmmoPrice: yup.number().min(0).required(),

  // Long term returns reproduction section
  longTermReturnsReproductionCostCurrent: yup.number().min(0).required(),
  longTermReturnsReplacementCostCurrent: yup.number().min(0).required(),
  longTermReturnsCullValueCurrent: yup.number().min(0).required(),
  longTermReturnsCalfValueCurrent: yup.number().min(0).required(),
  longTermReturnsLastMonthToBreedCurrent: yup.number().min(0).required(),
  longTermReturnsMinimumMilkToBreedCurrent: yup.number().min(0).required(),
  longTermReturnsCullingRateCurrent: yup.number().min(0).required(),
  longTermReturnsPregnancyRateCurrent: yup.number().min(0).required(),
  longTermReturnsPregnancyRateDifference: yup.number().required(),
  longTermReturnsPregnancyLossCurrent: yup.number().min(0).required(),
  longTermReturnsPregnancyLossDifference: yup.number().required(),

  // Long term returns health section
  longTermReturnsMastitisRegular: yup.number().min(0).required(),
  longTermReturnsMastitisReduced: yup.number().min(0).required(),
  longTermReturnsMastitisCostPerCase: yup.number().min(0).required(),
  longTermReturnsKetosisClinicalRegular: yup.number().min(0).required(),
  longTermReturnsKetosisClinicalReduced: yup.number().min(0).required(),
  longTermReturnsKetosisClinicalCostPerCase: yup.number().min(0).required(),
  longTermReturnsKetosisSubClinicalRegular: yup.number().min(0).required(),
  longTermReturnsKetosisSubClinicalReduced: yup.number().min(0).required(),
  longTermReturnsKetosisSubclinicalCostPerCase: yup.number().min(0).required(),
  longTermReturnsMetritisRegular: yup.number().min(0).required(),
  longTermReturnsMetritisReduced: yup.number().min(0).required(),
  longTermReturnsMetritisCostPerCase: yup.number().min(0).required(),
})
  .test('herd-structure-sum', herdSumErrMsg, validateHerdStructure('investmentHerdStructureFarOff'))
  .test('herd-structure-sum', herdSumErrMsg, validateHerdStructure('investmentHerdStructureCloseUp'))
  .test('herd-structure-sum', herdSumErrMsg, validateHerdStructure('investmentHerdStructureFresh'))
  .test('herd-structure-sum', herdSumErrMsg, validateHerdStructure('investmentHerdStructureEarlyMidLact'))
  .test('herd-structure-sum', herdSumErrMsg, validateHerdStructure('investmentHerdStructureLateLact'))
function validateHerdStructure(field: string) {
  return function (values: CalculationInput, context: yup.TestContext) {
    const sum = (
      values.investmentHerdStructureFarOff +
      values.investmentHerdStructureCloseUp +
      values.investmentHerdStructureFresh +
      values.investmentHerdStructureEarlyMidLact +
      values.investmentHerdStructureLateLact
    )
    if (sum !== 100) {
      return context.createError({path: field})
    }
    return true
  }
}

const schemaDescription = validationSchema.describe()


export type valuesType = yup.InferType<typeof validationSchema>


export function Index() {
  const [calculationResult, setCalculationResult] = React.useState<Calculate>()
  const [commonErrors, setCommonErrors] = React.useState<string[]>([])
  const [initialValues, setInitialValues] = React.useState<CalculationInput>()

  const [selectedDate, setSelectedDate] = React.useState<Date|null>(new Date())
  const [farm, setFarm] = React.useState('')
  const [nutritionist, setNutritionist] = React.useState('')
  const [notes, setNotes] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [isEmail, setIsEmail] = React.useState(false)

  const user = React.useContext(userContext)

  const notesState = {
    selectedDate,
    setSelectedDate,
    farm,
    setFarm,
    nutritionist,
    setNutritionist,
    notes,
    setNotes,
    isEmail,
    setIsEmail,
    email,
    setEmail,
  }

  const pdfRef = React.useRef<HTMLDivElement>(null)

  const constants = useQuery({
    currencies: {__scalar: 1},
    products: {__scalar: 1},
    standards: {
      __scalar: 1,
      region: {
        __scalar: 1,
        countries: {__scalar: 1},
        currency: {__scalar: 1},
      },
      feedingRates: {
        __scalar: 1,
        herdComposition: {__scalar: 1},
        dmi: {__scalar: 1},
        currentFeedCosts: {__scalar: 1},
        productFeedingRates: {
          __scalar: 1,
          product: {__scalar: 1},
        },
      },
      fmmoPricing: {__scalar: 1},
      milkPerformance: {__scalar: 1},
      milkPerformanceDiff: {__scalar: 1},
      reproductivePerformance: {__scalar: 1},
      economicPerformance: {__scalar: 1},
      mastitisClinicalPerformance: {__scalar: 1},
      ketosisClinicalPerformance: {__scalar: 1},
      ketosisSubclinicalPerformance: {__scalar: 1},
      metritisPerformance: {__scalar: 1},
    }
  })

  const standards = getStandards()
  let currency = standards?.region.currency
  if (constants.data) {
    if (user.data?.country === MainUserCountryChoices.JP) {
      currency = constants.data.currencies.find(c => c.code === 'JPY') || currency
    } else if (user.data?.country === MainUserCountryChoices.BR) {
      currency = constants.data.currencies.find(c => c.code === 'BRL') || currency
    } else if (user.data?.country === MainUserCountryChoices.GB) {
      currency = constants.data.currencies.find(c => c.code === 'GBP') || currency
    }
  }

  React.useEffect(() => {
    if (!constants.data || !currency) return

    const product = constants.data.products[0]
    const standards = getStandards()!
    const productFeedingRates = standards.feedingRates.productFeedingRates
      .find(p => p.product.id === product.id)

    const values = renderValues(
      {
        units: standards.region.units as string,
        resultsUnitsAmount: ResultsUnitsAmount.COW,
        resultsUnitsPeriod: ResultsUnitsPeriod.YEAR,
        currencyId: currency.id,
        herdSize: 1000,
        aabCents: 0,
        isPlusDmi: false,
        isAab: true,
        productId: product.id,
        productPrice: product.price,
        regionId: standards.region.id,

        investmentHerdStructureFarOff: standards.feedingRates.herdComposition.farOff,
        investmentHerdStructureCloseUp: standards.feedingRates.herdComposition.closeUp,
        investmentHerdStructureFresh: standards.feedingRates.herdComposition.fresh,
        investmentHerdStructureEarlyMidLact: standards.feedingRates.herdComposition.earlyMidLact,
        investmentHerdStructureLateLact: standards.feedingRates.herdComposition.lateLact,

        investmentProductGramsPerCowDayCloseUp: productFeedingRates!.closeUp,
        investmentProductGramsPerCowDayFresh: productFeedingRates!.fresh,
        investmentProductGramsPerCowDayEarlyMidLact: productFeedingRates!.earlyMidLact,
        investmentProductGramsPerCowDayLateLact: productFeedingRates!.lateLact,

        investmentDmiFarOff: standards.feedingRates.dmi.farOff,
        investmentDmiCloseUp: standards.feedingRates.dmi.closeUp,
        investmentDmiFresh: standards.feedingRates.dmi.fresh,
        investmentDmiEarlyMidLact: standards.feedingRates.dmi.earlyMidLact,
        investmentDmiLateLact: standards.feedingRates.dmi.lateLact,

        investmentDmCostCurrentFarOff: standards.feedingRates.currentFeedCosts.farOff,
        investmentDmCostCurrentCloseUp: standards.feedingRates.currentFeedCosts.closeUp,
        investmentDmCostCurrentFresh: standards.feedingRates.currentFeedCosts.fresh,
        investmentDmCostCurrentEarlyMidLact: standards.feedingRates.currentFeedCosts.earlyMidLact,
        investmentDmCostCurrentLateLact: standards.feedingRates.currentFeedCosts.lateLact,


        shortTermReturnsMilkCurrent: standards.milkPerformance.milk,
        shortTermReturnsMilkDifference: standards.milkPerformanceDiff.milk,

        shortTermReturnsFatCurrent: standards.milkPerformance.fat,
        shortTermReturnsFatDifference: standards.milkPerformanceDiff.fat,

        shortTermReturnsProteinCurrent: standards.milkPerformance.protein,
        shortTermReturnsProteinDifference: standards.milkPerformanceDiff.protein,

        shortTermReturnsOtherSolidsCurrent: standards.milkPerformance.otherSolids,
        shortTermReturnsOtherSolidsDifference: standards.milkPerformanceDiff.otherSolids,

        shortTermReturnsMilkFmmoPrice: standards.fmmoPricing.milk,
        shortTermReturnsFatFmmoPrice: standards.fmmoPricing.fat,
        shortTermReturnsProteinFmmoPrice: standards.fmmoPricing.protein,
        shortTermReturnsOtherSolidsFmmoPrice: standards.fmmoPricing.otherSolids,
        shortTermReturnsPremiumFmmoPrice: standards.fmmoPricing.premium,

        longTermReturnsReproductionCostCurrent: standards.economicPerformance.reproductionCost,
        longTermReturnsReplacementCostCurrent: standards.economicPerformance.replacementCost,
        longTermReturnsCullValueCurrent: standards.economicPerformance.cullValue,
        longTermReturnsCalfValueCurrent: standards.economicPerformance.calfValue,
        longTermReturnsCullingRateCurrent: standards.economicPerformance.cullingRate,

        longTermReturnsLastMonthToBreedCurrent: standards.reproductivePerformance.lastMonthToBreed,
        longTermReturnsMinimumMilkToBreedCurrent: standards.reproductivePerformance.minimumMilkToBreed,
        longTermReturnsPregnancyRateCurrent: standards.reproductivePerformance.pregnancyRate,
        longTermReturnsPregnancyRateDifference: standards.reproductivePerformance.pregnancyRateDiff,
        longTermReturnsPregnancyLossCurrent: standards.reproductivePerformance.pregnancyLoss,
        longTermReturnsPregnancyLossDifference: standards.reproductivePerformance.pregnancyLossDiff,

        longTermReturnsMastitisRegular: standards.mastitisClinicalPerformance.regularIncidence,
        longTermReturnsMastitisReduced: standards.mastitisClinicalPerformance.reducedIncidence,
        longTermReturnsMastitisCostPerCase: standards.mastitisClinicalPerformance.usdCase,

        longTermReturnsKetosisClinicalRegular: standards.ketosisClinicalPerformance.regularIncidence,
        longTermReturnsKetosisClinicalReduced: standards.ketosisClinicalPerformance.reducedIncidence,
        longTermReturnsKetosisClinicalCostPerCase: standards.ketosisClinicalPerformance.usdCase,

        longTermReturnsKetosisSubClinicalRegular: standards.ketosisSubclinicalPerformance.regularIncidence,
        longTermReturnsKetosisSubClinicalReduced: standards.ketosisSubclinicalPerformance.reducedIncidence,
        longTermReturnsKetosisSubclinicalCostPerCase: standards.ketosisSubclinicalPerformance.usdCase,

        longTermReturnsMetritisRegular: standards.metritisPerformance.regularIncidence,
        longTermReturnsMetritisReduced: standards.metritisPerformance.reducedIncidence,
        longTermReturnsMetritisCostPerCase: standards.metritisPerformance.usdCase,
      } as CalculationInput,
      currency.code
    )
    const convertValues = async () => {
      return await convertCurrencyInputs(
        constants.data.products,
        (v: valuesType) => setInitialValues(v as any),
        1,
        currency!,
        values,
      )
    }
    convertValues().then((v) => calculate(v as unknown as CalculationInput))
  }, [constants.data])

  function calculate(values: CalculationInput) {
    setCommonErrors([])
    graphqlClient.chain.mutation
      .calculate({input: {
        ...(
          Object.fromEntries(
            Object.entries(values).map(([k, v]) => (
              [k, schemaDescription.fields[k].type === 'number' && typeof v === 'string' ? parseFloat(v) : v]
            ))
          )
        ) as CalculationInput
      }})
      .execute({
        __scalar: 1,
        currency: {__scalar: 1},
        investment: {
          __scalar: 1,
          cowsAmount: {__scalar: 1},
          productPricePerCowDay: {__scalar: 1},
          dmiWithProduct: {__scalar: 1},
          dmCostWithProduct: {__scalar: 1},
          feedCostPerCowDay: {__scalar: 1},
          feedCostPerCowDayWithProduct: {__scalar: 1},
        },
        product: {__scalar: 1},
        shortTermReturns: {
          __scalar: 1,
          fatPerCowDay: {__scalar: 1},
          proteinPerCowDay: {__scalar: 1},
          otherSolidsPerCowDay: {__scalar: 1},
          ecmPerCowDay: {__scalar: 1},
          impactCwtCost: {__scalar: 1},
          impactPerCowDayCost: {__scalar: 1},
          impactPerCowYearCost: {__scalar: 1},
          totalImpactCost: {__scalar: 1},
        },
        longTermReturnsReproduction: {
          __scalar: 1,
          calfSales: {__scalar: 1},
          mortalityCost: {__scalar: 1},
          reproductiveCost: {__scalar: 1},
          reproductiveCullingCost: {__scalar: 1},
          nonReproductiveCullingCost: {__scalar: 1},
          totalReturns: {__scalar: 1},
          totalReturnsFinal: {__scalar: 1},
        },
        longTermReturnsHealth: {
          mastitis: {__scalar: 1},
          ketosisClinical: {__scalar: 1},
          ketosisSubclinical: {__scalar: 1},
          metritis: {__scalar: 1},
          totalSavings: {__scalar: 1},
          totalSavingsFinal: {__scalar: 1},
        },
      })
      .then(r => setCalculationResult(r!))
      .catch(e => {
        if (e.errors[0].extensions?.common_errors) {
          setCommonErrors(e.errors[0].extensions.common_errors)
        } else {
          throw e
        }
      })
  }

  function getStandards() {
    if (!constants.data?.standards) return
    return (
      constants.data.standards.find(s => !!s.region.countries.find(c => c.code === user.data?.country))
      || constants.data.standards.find(s => s.region.name === 'Other')
      || constants.data.standards[0]
    )
  }

  if (!calculationResult || constants.spinner) return <CenteredSpinner />

  return (
    <mui.Box
      sx={{
        minHeight: '100vh',
        border: '7px solid #A70A2D',
      }}
    >
      <Formik<valuesType>
        initialValues={initialValues!}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          calculate(values as unknown as CalculationInput)
          setSubmitting(false)
        }}
      >
        {() => (
          <Form>
            <mui.Grid
              item
              xs={12}
              sx={{
                backgroundColor: '#A70A2D',
                borderBottom: '10px solid #B6C1C6',
                color: 'white',
                mr: '14px',
                mt: '-7px',
                py: 1,
                position: 'fixed',
                width: 'calc(100% - 14px)',
                zIndex: 1000,
              }}
            >
              <mui.Stack direction="row" sx={{justifyContent: 'space-between', pr: '300px'}}>
                <mui.Stack direction="row" spacing={2}>
                  <mui.Button
                    href="mailto:fernanda.lopes@adisseo.com,lucas.rebelo@adisseo.com,douglas.cornwell@adisseo.com"
                    sx={{color: '#B6C1C6', fontSize: '1.1rem'}}
                    variant="text"
                  >
                    Help
                  </mui.Button>
                  <mui.Button
                    onClick={() => graphqlClient.chain.mutation
                      .logout
                      .execute({__scalar: 1})
                      .then(() => window.location.reload())
                    }
                    sx={{color: '#B6C1C6', fontSize: '1.1rem'}}
                    variant="text"
                  >
                    Logout
                  </mui.Button>
                </mui.Stack>

                <mui.Typography
                  variant="h1"
                  sx={{fontFamily: 'Exo, Roboto, Helvetica, Arial, sans-serif'}}
                >
                  MilkSmart Calculator — Profitability of AA Balancing
                </mui.Typography>
                <div></div>
              </mui.Stack>

              <mui.Box sx={{position: 'absolute', right: 0, bottom: '-29px'}}>
                <img src={logoAdisseo} alt="Adisseo" style={{marginBottom: '5px'}} height="90"/>
              </mui.Box>
            </mui.Grid>
            <Main
              calculationResult={calculationResult}
              commonErrors={commonErrors}
              constants={constants}
              standards={standards!}
              initialCurrency={currency!}
              notesState={notesState}
              pdfRef={pdfRef}
            />
            <mui.Box sx={{position: 'fixed', left: 10000}}>
              <mui.Box ref={pdfRef}>
                <mui.Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: '#A70A2D',
                    borderBottom: '10px solid #B6C1C6',
                    color: 'white',
                    mb: 3,
                    position: 'relative',
                    py: 1,
                  }}
                >
                  <mui.Typography
                    variant="h2"
                    sx={{
                      fontFamily: 'Exo, Roboto, Helvetica, Arial, sans-serif',
                      textAlign: 'center',
                      ml: -30,
                    }}
                  >
                    MilkSmart Calculator Report
                  </mui.Typography>

                  <mui.Box sx={{position: 'absolute', right: 0, bottom: '-29px'}}>
                    <img src={logoAdisseo} alt="Adisseo" style={{marginBottom: '5px'}} height="90"/>
                  </mui.Box>
                </mui.Grid>
                <Main
                  calculationResult={calculationResult}
                  commonErrors={commonErrors}
                  constants={constants}
                  initialCurrency={currency!}
                  notesState={notesState}
                  standards={standards!}
                />
              </mui.Box>
            </mui.Box>
          </Form>
        )}
      </Formik>
    </mui.Box>
  )
}
